






import { Component, Vue } from "vue-property-decorator";
import AdminConfiguratorConfiguredConfigurationTable from "@/components/admin/configurator/configuration/configured/AdminConfiguratorConfiguredConfigurationTable.vue";

/**
 * AdminConfiguratorConfiguredConfigurationManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminConfiguratorConfiguredConfigurationTable,
    },
})
export default class AdminConfiguratorConfiguredConfigurationManagement extends Vue {}
