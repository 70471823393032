



















import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ConfiguratorConfiguredConfiguration } from "@/types/configurator";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminConfiguratorConfiguredConfigurationTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminConfiguratorConfiguredConfigurationTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private configuredConfigurations: ConfiguratorConfiguredConfiguration[] =
        [];

    private defaultConfiguredConfigurationItem: ConfiguratorConfiguredConfiguration =
        {
            id: -1,
            number: "",
            categoryId: -1,
            configurationGroupId: -1,
            configuration: {
                stepResults: [],
                properties: [],
                additionalItems: [],
                image: null,
                price: null,
                description: "",
            },
            steps: [],
        };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t(
                    "configurator.configuredConfiguration.number"
                ).toString(),
                value: "number",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.configuredConfigurations =
                await this.fetchConfiguredConfigurations();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all configured configurations
     *
     * @returns Promise<ConfiguratorConfiguredConfiguration[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchConfiguredConfigurations(): Promise<
        ConfiguratorConfiguredConfiguration[]
    > {
        const response = (
            await axios.get<APIResponse<ConfiguratorConfiguredConfiguration[]>>(
                "/admin/configurator/configuration/configured"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
